/* ------------------------------------- */
/* animations mixin */
/* ------------------------------------- */
export default {
  mounted() {
    /* preloader screen */
    this.animPreloaderScreen();

    // initialize animation effects
    window.addEventListener('load', () => this.initAnimation());
  },
  methods: {
    // preloader screen
    animPreloaderScreen() {
      let count = 0;
      const preloader = this.$refs.preloader;

      if (!preloader) { return; }

      const preloaderContent = preloader.querySelector(".preloader-content");
      const imgs = [...document.images];
      const imgsLength = imgs.length;
      const hidePreloader = () => {
        preloader.setAttribute("style", "--loading-percentage: 100%");
        gsap.timeline()
          .set(".hide-in-preloading", { autoAlpha: 1 })
          .to(preloaderContent, { delay: 0.5, autoAlpha: 0 })
          .to(preloader, { y: "-100%", duration: 1, ease: "expo.in" }, "-=0.5")
          .set(preloader, { autoAlpha: 0 });
      }
      const imgLoaded = () => {
        count++;

        this.loadingPercentage = ((100 / imgsLength) * count) << 0;
        preloader.setAttribute("style", `--loading-percentage: ${this.loadingPercentage}%`);

        if (count === imgsLength) { hidePreloader(); }
      }

      if (imgsLength) {

        // setup preloader indicator
        imgs.forEach(img => {
          const tImg = new Image();
  
          tImg.onload = imgLoaded;
          tImg.onerror = imgLoaded;
          tImg.src = img.src;
        });

      } else { hidePreloader(); }
    },

    // initialize animation effects
    initAnimation() {
      gsap.registerPlugin(ScrollTrigger);

      /* back to top scroll indicator */
      this.animBackTopScrollIndicator();

      /* statistics items */
      this.animStatisticsItems();

      /* section text box */
      this.animSectionTextBox();

      /* about image */
      this.animAboutImage();

      /* skills items */
      this.animSkillsItems();

      /* experience items timeline */
      this.animExperienceItemsTimeline();

      /* testimonials section title */
      this.animTestimonialsSectionTitle();

      /* testimonials items */
      this.animTestimonialsItems();

      /* contact info */
      this.animContactInfo();

      /* contact form */
      this.animContactForm();
    },

    // back to top scroll indicator
    animBackTopScrollIndicator() {
      const backTopBtn = this.$refs.scrollTopBtn;

      if (!backTopBtn) { return; }

      const showAt = backTopBtn.getAttribute('data-show-at');
      const backTopBtnPath = backTopBtn.querySelector("path");
      const backTopBtnPathLength = backTopBtnPath.getTotalLength();

      gsap.from(backTopBtn, {
        ease: "none",
        duration: 0.3,
        autoAlpha: 0,
        y: 10,
        scrollTrigger: {
          trigger: "#app-inner",
          start: `${showAt}px top`,
          end: "bottom bottom",
          toggleActions: "play none none reverse",
        },
      });

      gsap.set(backTopBtnPath, {
        strokeDasharray: backTopBtnPathLength,
        strokeDashoffset: backTopBtnPathLength,
        scrollTrigger: {
          trigger: "#app-inner",
          start: `${showAt}px top`,
          end: "bottom bottom",
          onUpdate: (self) => backTopBtnPath.style.strokeDashoffset = backTopBtnPathLength - (self.progress * backTopBtnPathLength),
        },
      });
    },

    // statistics items
    animStatisticsItems() {
      const statisticsItems = gsap.utils.toArray(".statistics-section .statistics-items li");

      if (!statisticsItems.length) { return; }

      const statisticsItemsTL = gsap.timeline({
        scrollTrigger: {
          trigger: ".statistics-items",
          start: "top 82%",
          end: "top 50%",
          scrub: 0.3,
        }
      });

      statisticsItems.forEach((el, i) => {
        const pos = i === 0 ? "" : "< +=0.2";
        statisticsItemsTL.from(el, { autoAlpha: 0 }, pos).from(el, { y: 50, }, "<");
      });
    },

    // section text box
    animSectionTextBox() {
      const textBoxes = gsap.utils.toArray(".text-box-inline");

      if (!textBoxes.length) { return; }

      textBoxes.forEach(box => {
        gsap.timeline({
          scrollTrigger: {
            trigger: box,
            start: "top 85%",
            end: "top 35%",
            scrub: 0.3,
          }
        })
          .from(box.querySelector(".subtitle"), { autoAlpha: 0, top: 50 })
          .from(box.querySelector("h2"), { autoAlpha: 0, y: 50 }, "-=0.2")
          .from(box.querySelectorAll("h2 ~ *"), { autoAlpha: 0, y: 50, stagger: 0.2 }, "-=0.2");
      });
    },

    // about image
    animAboutImage() {
      if (!this.$refs.aboutSection) { return; }

      gsap.timeline({
        scrollTrigger: {
          trigger: ".about-section .about-img",
          start: "top 80%",
          end: "top 50%",
          scrub: 0.3,
        }
      })
        .from(".about-section .about-img", { autoAlpha: 0, scale: 0.5 });
    },

    // skills items
    animSkillsItems() {
      const skillsGroups = gsap.utils.toArray(".skills-section .skills-items ul");

      if (!skillsGroups.length) { return; }

      skillsGroups.forEach(group => {
        const skillsItemsTL = gsap.timeline({
          scrollTrigger: {
            trigger: ".skills-section .skills-items",
            start: "top 85%",
            end: "top 35%",
            scrub: 0.3,
          }
        });

        group.querySelectorAll("li").forEach((el, i) => {
          const pos = i === 0 ? "" : "< +=0.2";
          skillsItemsTL.from(el, { autoAlpha: 0 }, pos).from(el, { y: 50, }, "<");
        });
      });
    },

    // experience items timeline
    animExperienceItemsTimeline() {
      const experienceTimepath = this.$refs.experienceTimepath;
      const experienceItems = gsap.utils.toArray(".experience-timeline .timeline-items li");
      let experienceTimepathTL;
      let experienceItemsTL;
      let mainExperienceTL;

      if (experienceTimepath || experienceItems.length) {
        mainExperienceTL = gsap.timeline({
          scrollTrigger: {
            trigger: ".experience-section .experience-timeline",
            start: "top 85%",
            end: "top 35%",
            scrub: 0.3,
          }
        });
      }

      if (experienceTimepath) {
        const experienceTimepathItems = gsap.utils.toArray(".experience-timeline .timepath span");
        experienceTimepathTL = gsap.timeline();

        const docDir = document.documentElement.dir;
        const fromDir = docDir === "rtl" ? "reverse" : "from";
        const reverseDir = docDir === "rtl" ? "from" : "reverse";
        const coords = {
          x: {
            from: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
            reverse: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
            to: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          },
          c: {
            from: "polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 75%, 0% 75%, 0% 75%, 0% 75%)",
            reverse: "polygon(100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 75%, 100% 75%, 100% 75%, 100% 75%)",
            to: {
              from: {
                st1: "polygon(0% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 75% 25%, 75% 25%, 0% 25%)",
                st2: "polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 75% 75%, 75% 75%, 75% 25%, 0% 25%)",
                st3: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 75%, 75% 75%, 75% 25%, 0% 25%)",
              },
              reverse: {
                st1: "polygon(100% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 25% 25%, 25% 25%, 100% 25%)",
                st2: "polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 25% 75%, 25% 75%, 25% 25%, 100% 25%)",
                st3: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 75%, 25% 75%, 25% 25%, 100% 25%)",
              },
            },
          },
        };
        const lineOdd = [...experienceTimepath.querySelectorAll(".line:nth-of-type(4n + 1)")];
        const lineEven = [...experienceTimepath.querySelectorAll(".line:nth-of-type(4n + 3)")];
        const semicircleOdd = [...experienceTimepath.querySelectorAll(".semicircle:nth-of-type(4n + 2)")];
        const semicircleEven = [...experienceTimepath.querySelectorAll(".semicircle:nth-of-type(4n + 4)")];
        experienceTimepathTL
          .set(experienceTimepathItems, { autoAlpha: 1 })
          .set(lineOdd, { clipPath: coords.x[fromDir] })
          .set(lineEven, { clipPath: coords.x[reverseDir] })
          .set(semicircleOdd, { clipPath: coords.c[fromDir] })
          .set(semicircleEven, { clipPath: coords.c[reverseDir] });

        experienceTimepathItems.forEach(el => {
          if (lineOdd.includes(el) || lineEven.includes(el)) {
            experienceTimepathTL.to(el, { clipPath: coords.x.to });

          } else if (semicircleOdd.includes(el)) {
            experienceTimepathTL
              .to(el, { clipPath: coords.c.to[fromDir].st1 })
              .to(el, { clipPath: coords.c.to[fromDir].st2 })
              .to(el, { clipPath: coords.c.to[fromDir].st3 });

          } else if (semicircleEven.includes(el)) {
            experienceTimepathTL
              .to(el, { clipPath: coords.c.to[reverseDir].st1 })
              .to(el, { clipPath: coords.c.to[reverseDir].st2 })
              .to(el, { clipPath: coords.c.to[reverseDir].st3 });
          }
        });

        mainExperienceTL.add(experienceTimepathTL);
      }

      if (experienceItems.length) {
        experienceItemsTL = gsap.timeline();

        experienceItems.forEach(el => {
          experienceItemsTL.from(el, { autoAlpha: 0 }).from(el, { scale: 0.2, }, "<");
        });

        mainExperienceTL.add(experienceItemsTL, "< +=0.5");
      }
    },

    // testimonials section title
    animTestimonialsSectionTitle() {
      if (!this.$refs.testimonialsSection) { return; }

      gsap.timeline({
        scrollTrigger: {
          trigger: ".testimonials-section .section-title",
          start: "top 90%",
          end: "top 40%",
          scrub: 0.3,
        }
      })
        .from(".testimonials-section .section-title .subtitle", { autoAlpha: 0, top: 50 })
        .from(".testimonials-section .section-title .title", { autoAlpha: 0, y: 50 }, "< +=0.2");
    },

    // testimonials items
    animTestimonialsItems() {
      if (!this.$refs.testimonialsSection) { return; }

      const testimonialsItems = gsap.utils.toArray(".testimonials-section .testimonials-item");
      const testimonialsItemsTL = gsap.timeline({
        scrollTrigger: {
          trigger: ".testimonials-section .testimonials-items",
          start: "top 75%",
          end: "top 25%",
          scrub: 0.3,
        }
      });

      testimonialsItems.forEach((el, i) => {
        const pos = i === 0 ? "" : "< +=0.2";
        testimonialsItemsTL.from(el, { autoAlpha: 0 }, pos).from(el, { scale: 0.2, }, "<");
      });
    },

    // contact info
    animContactInfo() {
      const contactInfoItems = gsap.utils.toArray(".contact-section .contact-info li");

      if (!contactInfoItems.length) { return; }

      const contactInfoTL = gsap.timeline({
        scrollTrigger: {
          trigger: ".contact-section .contact-info",
          start: "top 80%",
          end: "top 50%",
          scrub: 0.3,
        }
      });

      contactInfoItems.forEach((el, i) => {
        const pos = i === 0 ? "" : "< +=0.2";
        contactInfoTL.from(el, { autoAlpha: 0 },pos).from(el, { y: 50, }, "<");
      });

      // social icons animation
      contactInfoTL
        .from(".contact-section .contact-text .social li", { autoAlpha: 0 })
        .from(".contact-section .contact-text .social li", { y: 50, stagger: 0.2 }, "<");
    },

    // contact form
    animContactForm() {
      if (!this.$refs.contactForm) { return; }

      gsap.timeline({
        scrollTrigger: {
          trigger: ".contact-section .contact-form",
          start: "top 80%",
          end: "top 50%",
          scrub: 0.3,
        }
      })
        .from(".contact-section .contact-form", { autoAlpha: 0, scale: 0.7 });
    },
  },
};
